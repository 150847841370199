@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300&display=swap');


.nav{
    background-color: #072C26DD;
    
}

.links{
    color: white;
    font-family: 'Jost', sans-serif;
    font-size: larger;
    font-weight: 600;
}