/* adding color variable  */
:root{
    --black:#2a2a2a;
    
}

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

.ModStyle{
    background: var(--black);
    color: #dcdcdc;

}