*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

.buttonlegend{
    font-size: 12pt;
}

.btn{
    border-radius: 36px;
    padding: 0 60px;
}

.titles{
   padding-left:80px;
   text-align: start;
   color: #FFFFFF;
    text-shadow: 1px 3px #000000;
    font-weight: 600;
    
   
}

p {
    color: #FFFFFF;
    text-shadow: 1px 2px #000000;
    font-weight: 600;
    

}