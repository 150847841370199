*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

.MainCards{
    background-color: #072C26DD;
    margin-top: 10px;
}

.tarjeta{
   border-radius: 50%;
   padding: 2rem;
    height: 20rem;
    width: 20rem;
}

.ActionCall{
    background-color: #FAF2BF   ;
    color: #072C26;
    height: 2rem;
}
/* 
@media screen and (max-width:1200) {
    .card-img{
        margin-top: 30rem;

    }
    
} */