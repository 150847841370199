img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .legend2{
    color: black;
  }
