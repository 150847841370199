.image{
    background-image:linear-gradient(to top, #023B22FF,#FFFFFFA0),url("../../assets/dinero.jpg");
}

.box{
    
}

.slide img{
    /* height: 100vh;
     */
     width: auto; 
     height: 70vh;
     
}