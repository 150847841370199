*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
}

.accordion-button{
    background-color: #072C26aa ;
    color: #020202;

}

.AccBody{
    background-color: #072C26AA;
    color: #FFFFFF;
}
.accordion-button:not(.collapsed) {
    color: white;
    background-color: black;
    box-shadow: inset 0 calc(-1 * #FFFFFF 0 #072C26DD);
}


